import React from "react"

const GlossarySearchForm = ({ handleInput }) => {
  return (
    <div className="form__field form__field--glossary mt-xxs">
      <label
        className="form__field__label form__field__label--glossary"
        for="glossary-search"
      >
        Search
      </label>
      <input
        onChange={handleInput}
        placeholder="Search our Glossary"
        className="form__field__input"
        name="glossary-search"
        type="search"
      />
    </div>
  )
}

export default GlossarySearchForm
