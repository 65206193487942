import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Heading from "../components/content/heading"
import Section from "../components/containers/section"
import SectionContent from "../components/containers/section-content"
import Rows from "../components/grid/rows"
import Columns from "../components/grid/columns"
import { Content } from "../components/grid"
import Main from "../components/layout/main"
import Seo from "../components/seo/seo"
import Copy from "../components/content/copy"
import SectionBackground from "../components/containers/section-background"
import GlossarySearchForm from "@src/components-smart/forms/glossary-search-form"
import GlossaryListing from "@src/components/sections/glossary-listing"

function GlossaryPage({ data, location }) {
  const [filter, setFilter] = useState(false)

  const seoObj = {
    title: "Performance Marketing Glossary | Lunio",
    description:
      "Trying to keep up with the jargon in the performance marketing world? Our glossary covers everything you need to know, from PPC metrics to ad campaign types.",
    url: location && location.pathname,
  }

  const handleInputField = e => {
    if (e.target.value === "") return setFilter(false)

    const filteredArray = data.allWpGlossaryCategory.nodes.filter(i => {
      return i.glossaries.nodes.filter(j => {
        return j.title.toLowerCase().includes(e.target.value.toLowerCase())
      }).length
    })

    setFilter(filteredArray)
  }

  return (
    <Main>
      <Seo customData={seoObj} />
      <header>
        <Section>
          <SectionBackground>
            <div className="hero-image--careers" />
          </SectionBackground>
          <SectionContent paddingTop="lg" hero>
            <Rows gap="none">
              <Columns count="2,1" alignment="center">
                <Content paddingLeft="none" paddingRight="none">
                  <Heading level={1}>
                    PPC Marketing{" "}
                    <span className="text-gradient text-gradient__nebula">
                      Glossary
                    </span>
                  </Heading>
                  <Copy className="lap-mr-md">
                    <p className="tab-mr-xl">
                      Want to learn the lingo of performance marketing pros? Our
                      A to Z marketing glossary covers everything you need to
                      know from ad management to click fraud. Get ready to
                      become a performance marketing hero!
                    </p>
                  </Copy>
                  <GlossarySearchForm handleInput={handleInputField} />
                </Content>
                <Content
                  paddingRight="none"
                  className="flex flex--justify-end hidden--tab-down"
                >
                  <div className="hero-image--reading">
                    <StaticImage src="../images/common/red-reading.png" />
                  </div>
                </Content>
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section>
        <SectionContent paddingBot="xs" paddingTop="none">
          <GlossaryListing
            itemList={filter || data.allWpGlossaryCategory.nodes}
          />
        </SectionContent>
      </Section>
    </Main>
  )
}

export const query = graphql`
  query GlossaryPageQuery {
    allWpGlossaryCategory {
      nodes {
        glossaries {
          nodes {
            title
            slug
          }
        }
        name
      }
    }
  }
`

export default GlossaryPage
