import React from "react"
import Columns from "../grid/columns"
import Rows from "../grid/rows"
import { Link as ScrollLink } from "react-scroll"
import Link from "../content/link"
import Content from "../grid/content"
import Heading from "../content/heading"

const GlossaryListing = ({ itemList }) => {
  return (
    <Columns className="grid__columns--glossary">
      <Rows gap="xs">
        {itemList.length ? (
          itemList.map((item, nIndex) =>
            item.glossaries.nodes.length ? (
              <p>
                <ScrollLink
                  className="link h3"
                  key={nIndex}
                  smooth
                  offset={-120}
                  to={item.name}
                >
                  {item.name}
                </ScrollLink>
              </p>
            ) : null,
          )
        ) : (
          <p className="h3">:(</p>
        )}
      </Rows>
      <Rows gap="sm">
        {itemList.length ? (
          itemList.map((item, gIndex) =>
            item.glossaries.nodes.length ? (
              <Columns key={gIndex} count="1" className="mb-md">
                <Content>
                  <p className="h2" id={item.name}>
                    {item.name}
                  </p>
                </Content>
                <Rows>
                  <Columns count="3">
                    {item.glossaries.nodes.map((definition, dIndex) => {
                      return (
                        <Content key={dIndex}>
                          <p>
                            <Link to={`/glossary/${definition.slug}/`}>
                              {definition.title}
                            </Link>
                          </p>
                        </Content>
                      )
                    })}
                  </Columns>
                </Rows>
                <div className="mt-sm card--career-listing__border" />
              </Columns>
            ) : null,
          )
        ) : (
          <Content>
            <Heading level={2}>No Results Found</Heading>
            <p>Try searching for something else</p>
          </Content>
        )}
      </Rows>
    </Columns>
  )
}

export default GlossaryListing
